<template>
  <div>
    <b-modal
      id="remindContractModal"
      size="lg"
      scrollable
      title="Minna undirritendur á að skrifa undir samning"
      lazy
      ok-title="Senda áminningu"
      cancel-title="Hætta við"
      @ok="accept"
      ref="remindModal"
    >
      <div>
        <strong>{{ lastReminder ? `Síðasta áminning var send ${lastReminder}` : 'Engin áminning hefur verið send' }}</strong>
      </div>
      <b-table :items="contacts" :fields="fields">
        <template #cell(check)="check">
          <i v-if="check.value" class="fa fa-check-circle-o signed-icon"></i>
          <i v-else class="fa fa-circle-o unsigned-icon"></i>
        </template>
        <template #cell(email)="email">
          <div v-if="currentlyEditingEmailIndex === email.index">
            <b-input-group>
              <b-form-input v-model="newEmailValue" type="email" />
              <b-input-group-append>
                <button type="button" class="btn btn-sm btn-secondary" @click="editEmail(null)">
                  <i class="fa fa-times"></i>
                </button>
                <button type="button" class="btn btn-sm btn-primary" @click="confirmNewEmail(email.index)">
                  <i class="fa fa-check"></i>
                </button>
              </b-input-group-append>
            </b-input-group>
          </div>
          <div v-else>
            <a :href="'mailto:' + email.value">{{ email.value }}</a>
            <b-link v-if="email.item.canEdit" class="editButton" href @click="editEmail(email.index, email.value)">
              <i class="fa fa-pencil"></i>
            </b-link>
          </div>
        </template>
      </b-table>
      <i>Breyting á netfangi hér, hefur einungis áhrif á þennan samning.</i>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';

import book from '@/api/book';

export default {
  name: 'contract-remind-modal',
  data() {
    return {
      id: null,
      lastReminder: null,
      currentlyEditingEmailIndex: null,
      newEmailValue: '',
      fields: [
        {
          key: 'check',
          label: '',
        },
        {
          key: 'name',
          label: 'Nafn',
        },
        {
          key: 'email',
          label: 'Netfang',
        },
        {
          key: 'signedAt',
          label: 'Undirritaði',
        },
      ],
      contacts: [
        {
          check: false,
          name: null,
          email: null,
          signedAt: null,
          canEdit: true,
        },
      ],
    };
  },
  methods: {
    show({ id, lastReminder, contacts }) {
      this.id = id;
      this.lastReminder = lastReminder ? moment(lastReminder).format('DD.MM.YYYY') : null;
      this.contacts = contacts.map((c) => ({
        check: !!c.signedAt,
        name: c.name,
        email: c.email,
        signedAt: c.signedAt ? moment(c.signedAt).format('DD.MM.YYYY') : null,
        canEdit: c.signedAt === null,
      }));
      this.$refs.remindModal.show();
    },
    accept() {
      this.$emit('accept', this.id);
    },
    editEmail(index, email = '') {
      this.currentlyEditingEmailIndex = index;
      this.newEmailValue = email; // Set the input field to the current email
    },
    async confirmNewEmail(index) {
      try {
        const contractId = this.id;
        await book.contractContactUpdateEmail(contractId, {
          contactName: this.contacts[index].name,
          contactOldEmail: this.contacts[index].email,
          contactNewEmail: this.newEmailValue,
        });

        // Update the displayed email after successful save
        this.contacts[index].email = this.newEmailValue;

        // Reset editing state
        this.editEmail(null);

        // Refetch the updated data to ensure parent component is in sync
        this.$emit('refetch');
      } catch (error) {
        this.$log.error(error);
      }
    },
  },
};
</script>

<style scoped>
ul {
  list-style-type: none;
  padding: 0px;
}
.signed-icon {
  color: green;
}
.unsigned-icon {
  color: lightgray;
}
.fa {
  margin-right: 3px;
}
.editButton {
  margin-left: 10px;
}
</style>
